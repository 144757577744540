.sidebar-list {
   overflow-y: scroll;
   padding: 12px 36px 0 36px;
   flex-direction: column;
}

.sidebar-list::-webkit-scrollbar {
   display: none;
}

::-webkit-scrollbar {
   width: 5px;
}

::-webkit-scrollbar-track {
   background-color: #f1f1f1f1;
}

::-webkit-scrollbar-thumb {
   background-color: lightgray;
}

.sidebar-wrapper {
   padding: 0 36px;
}

.sidebar-list-add-contact-button-block {
   position: absolute;
   width: 100%;
   bottom: 0;
   left: 0;
   padding: 35px 36px;
   display: flex;
   flex-direction: column;
   background-color: #f5f6fa;
}

.sidebar-list-add-contact-button {
   width: 337px;
   text-align: center;
   background-color: #00acef;
   color: white;
   border-radius: 6px;
   height: 40px;
   border: none;
   margin-bottom: 20px;
   cursor: pointer;
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 19px;
}

.dialogs-filter {
   padding: 0 36px;
   margin: 36px 0 20px;
   display: flex;
   flex-direction: column;
   gap: 8px;
}

.dialogs-filter__search-container {
   display: flex;
   gap: 10px;
}

.dialogs-filter__toggle-filter-btn {
   width: 36px;
   height: 36px;
   border-radius: 6px;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: white;
   cursor: pointer;
}

.dialogs-filter__filter-container {
   display: none;
}

.dialogs-filter__filter-container_show {
   display: flex;
   flex-direction: column;
   gap: 8px;
}

.sidebar-card {
   width: 100%;
   background-color: #fff;
   border-radius: 6px;
   margin-bottom: 20px;
   padding: 18px 20px 18px 20px;
   cursor: pointer;
   position: relative;
   box-shadow: 0px 6px 20px rgba(213, 230, 235, 0.4);
}

.sidebar-card-chosen {
   width: 100%;
   background-color: #bdebf8;
   border-radius: 6px;
   margin-bottom: 20px;
   padding: 18px 20px 18px 20px;
   cursor: pointer;
   position: relative;
   box-shadow: 0px 6px 20px rgba(213, 230, 235, 0.4);
}

.sidebar-card:hover {
   background-color: #def8ff;
}

.sidebar-card:active {
   background-color: #bdebf8;
}

.sidebar-card-wrapper {
   display: flex;
   align-items: center;
   flex-direction: row;
   margin-bottom: 12px;
   gap: 5px;
}

.sidebar-card-status {
   position: absolute;
   border: none;
   outline: none;
   box-shadow: none;
   min-height: 15px;
   min-width: 160px;
   cursor: pointer;
   top: -10px;
   font-size: 13px;
   border-radius: 6px;
   text-align: center;
   line-height: 15px;
   padding: 4px 5px;
   left: 152px;
   height: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 12px;
}

.sidebar-card-profile-avatar {
   position: relative;
   flex: 0 0 auto;
   margin-right: 14px;
}

.sidebar-card-profile-avatar__status-indicator {
   width: 10px;
   height: 10px;
   border-radius: 50%;
   background-color: #D94040;
   position: absolute;
   bottom: 7px;
   right: 0;
}

.sidebar-card-profile-avatar-pic {
   height: 42px;
   width: 42px;
   border-radius: 50%;
   object-fit: cover;
}

.sidebar-card-profile-channel-icon {
   position: absolute;
   top: 30px;
   left: 30px;
   border-radius: 50%;
   width: 14px;
   height: 14px;
}

.sidebar-card-conversation-details {
   width: 100%;
   display: flex;
   position: relative;
   flex-direction: column;
   justify-content: center;
   overflow: hidden;
   gap: 7px;
}

.sidebar-card-conversation-respondent {
   font-size: 16px;
   line-height: 19px;
   font-weight: 600;
   overflow: hidden;
   text-overflow: ellipsis;
}

.sidebar-card-conversation-product-name {
   font-weight: 400;
   font-size: 14px;
   line-height: 16.94px;
   color: rgba(64, 183, 217, 1);
   overflow: hidden;
   text-wrap: nowrap;
   text-overflow: ellipsis;
}

.sidebar-card-conversation-footer {
   display: flex;
   gap: 5px;
}

.sidebar-card-conversation-message {
   color: #252525;
   font-size: 14px;

   font-style: normal;
   font-weight: 400;
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
   width: 90%;
}

.sidebar-card-conversation-info {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 7px;
}

.sidebar-card-new-message-time-block {
   position: absolute;
   right: 15px;
   bottom: 5px;
}

.sidebar-card-new-message-status {
   width: 20px;
   height: 20px;
   border-radius: 50%;
   font-size: 10px;
   background: #40b7d9;
   color: #fff;
   display: flex;
   justify-content: center;
   align-items: center;
   vertical-align: middle;
   align-self: flex-end;
   font-weight: 500;
}

.sidebar-card-conversation-time {
   font-family: 'Roboto';
   text-align: end;
   font-size: 12px;
   font-weight: 400;
   color: rgba(37, 37, 37, 0.3);
   line-height: 12px;
}

.sidebar-contact-card {
   box-shadow: 0px 6px 20px rgba(213, 230, 235, 0.4);
   border-radius: 6px;
   width: 100%;
   background-color: #fff;
   margin-bottom: 20px;
   padding: 16px 20px;
   display: flex;
   align-items: center;
   cursor: pointer;
   position: relative;
}

.sidebar-contact-card:hover {
   background-color: #def8ff;
}

.sidebar-contact-card:active {
   background-color: #bdebf8;
}

.sidebar-contact-card-chosen {
   box-shadow: 0px 6px 20px rgba(213, 230, 235, 0.4);
   border-radius: 6px;
   width: 100%;
   background-color: #def8ff;
   margin-bottom: 20px;
   padding: 16px 20px;
   display: flex;
   align-items: center;
   cursor: pointer;
   position: relative;
}

.sidebar-list-add-button-block {
   position: absolute;
   width: 100%;
   bottom: 0;
   left: 0;
   padding: 35px 36px;
   display: flex;
   flex-direction: column;
   background-color: #f5f6fa;
}

.sidebar-card-conversation-tags-list {
   display: flex;
   flex-wrap: wrap;
   min-height: 25px;
   gap: 6px;
}

.sidebar-card-conversation-tags-button {
   position: absolute;
   left: 280px;
   bottom: 20px;
}

.sidebar-card-conversation-single-tag {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding: 4px 8px;
   gap: 5px;

   height: 23px;
   background: #f1fbfc;
   border-radius: 100px;
   color: #158cae;
}

.sidebar-conversation-single-tag-name {
   float: left;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   display: flex;
   align-items: center;
   font-size: 12px;
   line-height: 15px;
}

.sidebar-bot-settings-card {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   background-color: #fff;
   border-radius: 10px;
   margin-bottom: 20px;
   padding: 20px 18px;
   font-size: 14px;
   font-family: 'Roboto';
   line-height: 18.75px;
   cursor: pointer;
}

.sidebar-bot-settings-card-active {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   background-color: #bdebf8;
   border-radius: 10px;
   margin-bottom: 20px;
   padding: 20px 18px;
   font-size: 14px;
   font-family: 'Roboto';
   line-height: 18.75px;
   cursor: pointer;
}

.sidebar-bot-settings-card:hover {
   background-color: #def8ff;
}

.sidebar-bot-settings-card:active {
   background-color: #bdebf8;
}

.sidebar-bot-settings-card_block {
   display: flex;
   align-items: center;
   gap: 18px;
}

.sidebar-bot-settings-card_image {
   display: flex;
   align-items: center;
}

.sidebar-conversation-single-tag-controls {
   background-color: transparent;
   border: none;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

@media screen and (max-width: 575.98px) {
   .sidebar-list {
      padding: 12px 5px 0;
   }

   .sidebar-card-status {
      left: 20px;
   }
}

@media screen and (max-width: 424.98px) {
   .sidebar-card {
      padding: 18px 15px;
   }

   .sidebar-card-status {
      left: 15px;
   }

   .sidebar-card-conversation-respondent {
      font-size: 14px;
   }

   .sidebar-card-profile-avatar {
      margin-right: 10px;
   }
}